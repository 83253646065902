import React, { useState, useEffect } from "react";
import Slider, { Settings } from "react-slick";
import { Card, CardMedia, CardContent, Typography } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useWindowSize } from "utils";

interface ProviderImage {
  provider: string;
  image: string;
}

interface ProducSliderProps {
  providers: string[];
  providerImages: ProviderImage[];
  onSelectProvider: (provider: string) => void;
}

export const getSlidesToShow = (
  width: number,
  providerCount: number
): number => {
  const calculatedSlides = Math.floor((width / 1024) * 5);
  const slidesToShow =
    calculatedSlides > providerCount ? providerCount : calculatedSlides;
  return slidesToShow < 1 ? 1 : slidesToShow;
};
export const getArticlesToShow = (
  width: number,
  providerCount: number
): number => {
  const calculatedSlides = Math.floor((width / 1024) * 3);
  const slidesToShow =
    calculatedSlides > providerCount ? providerCount : calculatedSlides;
  return slidesToShow < 1 ? 1 : slidesToShow;
};
const getCarHeight = (width: number): string => {
  if (width >= 1024) return "h-32";
  if (width >= 768) return "h-24";
  if (width >= 480) return "h-24";
  return "h-24";
};

export const ProducSlider: React.FC<ProducSliderProps> = ({
  providers,
  providerImages,
  onSelectProvider,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [slidesToShow, setSlidesToShow] = useState(
    getSlidesToShow(windowWidth, providers.length)
  );
  const { isMobile } = useWindowSize();
  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth;
      setWindowWidth(newWidth);
      setSlidesToShow(getSlidesToShow(newWidth, providers.length));
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [providers.length]);

  const settings: Settings = {
    infinite: slidesToShow > 1,
    dots: isMobile,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    arrows: slidesToShow > 1,
    swipeToSlide: slidesToShow > 1,
    draggable: slidesToShow > 1,
    nextArrow: <SampleNextArrow isHovered={isHovered} />,
    prevArrow: <SamplePrevArrow isHovered={isHovered} />,
  };

  return (
    <div
      className="relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Slider {...settings}>
        {providers.map((provider, index) => {
          const providerImage = providerImages.find(
            (p) => p.provider === provider
          )?.image;
          return (
            <div
              key={index}
              onClick={() => onSelectProvider(provider)}
              className="p-2"
            >
              <Card className="cursor-pointer mx-auto max-w-xs">
                <CardContent>
                  {providerImage && (
                    <CardMedia
                      component="img"
                      image={providerImage}
                      alt={provider}
                      className={`${getCarHeight(
                        windowWidth
                      )} object-contain my-2 rounded-lg`}
                    />
                  )}
                  <Typography variant="h6" className="text-center">
                    {provider}
                  </Typography>
                </CardContent>
              </Card>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

interface ArrowProps {
  className?: string;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  isHovered: boolean;
}

const SampleNextArrow: React.FC<ArrowProps> = ({
  className,
  style,
  onClick,
  isHovered,
}) => (
  <div
    className={`slick-arrow slick-next absolute top-0 right-0 h-full flex items-center justify-center ${
      isHovered ? "opacity-100" : "opacity-0"
    } transition-opacity duration-300 ease-in-out`}
    style={{ ...style, display: "block" }}
    onClick={onClick}
  >
    <ArrowForwardIosIcon className="h-6 w-6 text-black" />
  </div>
);

const SamplePrevArrow: React.FC<ArrowProps> = ({
  className,
  style,
  onClick,
  isHovered,
}) => (
  <div
    className={`slick-arrow slick-prev absolute top-0 left-0 h-full flex items-center justify-center ${
      isHovered ? "opacity-100" : "opacity-0"
    } transition-opacity duration-300 ease-in-out`}
    style={{ ...style, display: "block" }}
    onClick={onClick}
  >
    <ArrowBackIosIcon className="h-6 w-6 text-black" />
  </div>
);
