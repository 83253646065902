import { SportsEsports } from "@mui/icons-material";
import { DevicesIcon, MediaIcon, ShoppingBagsIcon } from "./Icon";

export const HeaderMenu = [
  { name: "Page d'acceuil", path: "/" },
  { name: "Produits", path: "/products" },
  { name: "Prix", path: "/prix" },
  { name: "Nos services", path: "/about" },
  { name: "Contacts", path: "/Contacts" },
];

export const CategoriesMenu = [
  { name: "Gaming", path: "Gaming", icon: <SportsEsports /> },

  {
    name: "Musique et streaming",
    path: "Musique et streaming",
    icon: <MediaIcon />,
  },
  { name: "Services", path: "services", icon: <DevicesIcon /> },
  { name: "Shopping", path: "shoppings", icon: <ShoppingBagsIcon /> },
];