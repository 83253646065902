import { fetchPublic, ProductType, useWindowSize } from "../../../utils";
import { useEffect, useState } from "react";
import { Loading } from "../Loading";
import { motion } from "framer-motion";
import { Header } from "../Header";
import { Footer } from "../footer";
import { useUtility } from "../../../utils/context/UtilityContext";
import { Subtitle } from "../Subtitle";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { ProducSlider } from "./ProductSlider";

export const ProductsPages = () => {
  const [products, setProducts] = useState<ProductType[]>([]);
  const [loading, setLoading] = useState(true);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const { marginBottomHeader } = useUtility();
  const [showDialog, setShowDialog] = useState(false);
  const { isPC } = useWindowSize();
  const navigate = useNavigate();

  useEffect(() => {
    fetchProducts();
    const dialogPreference = localStorage.getItem("sliderDialogShown");
    if (!dialogPreference) {
      setShowDialog(true);
    }
  }, []);

  const fetchProducts = async () => {
    setLoading(true);
    const result = await fetchPublic(`/public/product`);
    if (result.products) {
      const productsArray = Object.values(result.products) as ProductType[];
      setProducts(productsArray);
    }

    setLoading(false);
  };
  if (loading) {
    return <Loading />;
  }
  const handleSelectProvider = (provider: string) => {
    navigate(`/provider/${provider}`);
  };
  const handleSort = () => {
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  };
  const handleCloseDialog = (dontShowAgain: boolean) => {
    if (dontShowAgain) {
      localStorage.setItem("sliderDialogShown", "true");
    }
    setShowDialog(false);
  };
  const categories = Array.from(
    new Set(products.map((product) => product.category))
  );

  const providersByCategory = categories.map((category) => {
    const providers = Array.from(
      new Set(
        products
          .filter((product) => product.category === category)
          .map((product) => product.provider)
      )
    );
    const providerImages = Array.from(
      new Set(
        products
          .filter((product) => product.category === category)
          .map((product) => ({
            provider: product.provider,
            image: product.image,
          }))
      )
    );

    const sortedProviders = providers.sort((a, b) => {
      if (sortOrder === "asc") {
        return a.localeCompare(b);
      } else {
        return b.localeCompare(a);
      }
    });

    return {
      category,
      providers: sortedProviders,
      providerImages,
    };
  });

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={`${marginBottomHeader} flex flex-col items-center justify-start gap-4 overflow-x-hidden fancy-scroll`}
    >
      <Header />
      <div
        className={` flex flex-col items-center justify-start gap-4 overflow-x-hidden fancy-scroll p-8 w-full ${
          isPC ? "px-16" : "px-1"
        }`}
      >
        <Dialog open={showDialog} onClose={() => handleCloseDialog(false)}>
          <DialogTitle>Le Saviez-vous ?</DialogTitle>
          <DialogContent>
            Vous pouvez faire défiler vers la droite pour voir plus d'articles !
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleCloseDialog(false)} sx={{color:'#104b7b'}}>OK</Button>
            <Button onClick={() => handleCloseDialog(true)} sx={{color:'#104b7b'}}>
              Ne plus afficher
            </Button>
          </DialogActions>
        </Dialog>

        <Button variant="contained" onClick={handleSort}>
          Trier par nom ({sortOrder === "asc" ? "A-Z" : "Z-A"})
        </Button>
        {providersByCategory.map(({ category, providers, providerImages }) => (
          <div key={category} className="w-full">
            <div
              className="cursor-pointer items-center justify-center flex w-full text-center"
              onClick={() => navigate(`/products/${category}`)}
            >
              <Subtitle
                title={category}
                textColor="#104b7b"
                textAlign="items-center"
                textSize="40px"
              />
            </div>

            <ProducSlider
              providers={providers}
              providerImages={providerImages}
              onSelectProvider={handleSelectProvider}
            />
          </div>
        ))}
      </div>

      <Footer />
    </motion.div>
  );
};
