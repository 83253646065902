import { useEffect, useState, useCallback, useMemo } from "react";
import { useParams, Link as RouterLink, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Header } from "../Header";
import { Footer } from "../footer";
import { ProductList } from "./ProductList";
import { useUtility } from "../../../utils/context/UtilityContext";
import { Loading } from "../Loading";
import {
  ProductType,
  useWindowSize,
  QAType,
  useDecodeToken,
  fetchPublic,
  getProducts,
  getYoutubeEmbedUrl,
  SetFlag,
} from "../../../utils";
import {
  Box,
  Breadcrumbs,
  Button,
  CardMedia,
  Typography,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  List,
  ListItem,
  ListItemText,
  Paper,
  Divider,
  Collapse,
  IconButton,
} from "@mui/material";
import {
  ArrowRight,
  ExpandLess,
  ExpandMore,
  SentimentDissatisfied as NoDataIcon,
} from "@mui/icons-material";
import { useAuth } from "../../../utils/context/authContext";
import { RssFeed } from "../RssFeed";

export const ProviderProductsPage = () => {
  const { provider } = useParams<{ provider: string }>();
  const { userFromToken, token, isLoadingToken } = useDecodeToken();
  const { login } = useAuth();
  const [, setLogged] = useState(false);
  const [products, setProducts] = useState<ProductType[]>([]);
  const [loading, setLoading] = useState(true);
  const [description, setDescription] = useState<string>("");
  const [videoUrl, setVideoUrl] = useState<string>("");
  const [providerLogo, setProviderLogo] = useState<string>("");
  const [selectedCountry, setSelectedCountry] = useState<string>(
    localStorage.getItem(`selectedCountry_${provider}`) || ""
  );
  const [externalUrl, setExternalUrl] = useState<string | null>(null);
  const [additionalInfo, setAdditionalInfo] = useState<string | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [expandedQA, setExpandedQA] = useState<string[]>([]);
  const { marginBottomHeader, cart } = useUtility();
  const { isMobile } = useWindowSize();
  const [qas, setQAs] = useState<QAType[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const handleLogin = async () => {
      if (token) {
        setLogged(true);
      }
      if (userFromToken) {
        login(userFromToken);
      }
      setLoading(false);
    };
    handleLogin();
  }, [token, userFromToken, login]);

  const fetchProducts = useCallback(async () => {
    setLoading(true);
    const products = await getProducts();
    const filteredProducts = products.filter(
      (product: ProductType) => product.provider === provider
    );
    setProducts(filteredProducts);
    setLoading(false);
  }, [provider]);

  const fetchProviderDescription = useCallback(async () => {
    const result = await fetchPublic(`/public/info/providers`);
    if (result.info && provider && result.info[provider]) {
      const providerData = result.info[provider];
      setDescription(providerData.description);
      setProviderLogo(providerData.url);

      if (providerData.videoUrl) {
        const video = getYoutubeEmbedUrl(providerData.videoUrl);
        setVideoUrl(video);
      }

      if (providerData.externalUrl) {
        setExternalUrl(providerData.externalUrl);
      }
      if (providerData.additionnalInfo) {
        setAdditionalInfo(providerData.additionnalInfo);
      }
    } else {
      console.error("Provider not found");
    }
  }, [provider]);

  const fetchProviderQAs = useCallback(async () => {
    const result = await fetchPublic(`/public/info/providers`);
    if (result.info && provider && result.info[provider]) {
      const providerData = result.info[provider];
      if (providerData.qas) {
        setQAs(providerData.qas);
      } else {
        setQAs([]);
      }
    } else {
      console.error("Provider not found");
    }
  }, [provider]);

  useEffect(() => {
    fetchProducts();
    fetchProviderDescription();
    fetchProviderQAs();
  }, [fetchProducts, fetchProviderDescription, fetchProviderQAs]);

  const handleCountrySelect = (country: string) => {
    localStorage.setItem(`selectedCountry_${provider}`, country);
    setSelectedCountry(country);
    setIsDialogOpen(false);
  };

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleExpandClick = (index: string) => {
    setExpandedQA((prevExpanded) =>
      prevExpanded.includes(index)
        ? prevExpanded.filter((i) => i !== index)
        : [...prevExpanded, index]
    );
  };
  const handleCheckout = () => {
    navigate("/cart-summary", { state: { cart } });
  };
  const filteredCountries = useMemo(() => {
    return products
      .map((product) => ({
        country: product.country,
        countryCode: product.countryCode,
      }))
      .filter(
        (value, index, self) =>
          self.findIndex((v) => v.country === value.country) === index
      )
      .filter((country) =>
        country.country.toLowerCase().includes(searchQuery.toLowerCase())
      );
  }, [products, searchQuery]);

  useEffect(() => {
    if (!selectedCountry && filteredCountries.length > 0) {
      setSelectedCountry(filteredCountries[0].country);
    }
  }, [filteredCountries, selectedCountry]);

  if (loading || isLoadingToken) {
    return <Loading />;
  }

  const getCountryCode = (country: string) => {
    const countryObj = filteredCountries.find((c) => c.country === country);
    return countryObj ? countryObj.countryCode : "";
  };

  const getCountryName = (country: string) => {
    const countryObj = filteredCountries.find((c) => c.country === country);
    return countryObj ? countryObj.country : "";
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={`${marginBottomHeader} flex flex-col items-center justify-start gap-4 overflow-x-hidden fancy-scroll`}
    >
      <Header />
      <Box
        className={`flex flex-col items-center justify-start gap-4 overflow-x-hidden fancy-scroll p-8 w-full`}
      >
        <div
          className="relative w-full h-72 flex flex-col items-center justify-center text-center bg-gradient-to-r from-gray to-variant_1"
          style={{ clipPath: "polygon(0 0, 100% 0, 100% 85%, 0 100%)" }}
        >
          <Breadcrumbs
            aria-label="breadcrumb"
            sx={{
              marginBottom: 2,
              display: "flex",
              width: "100%",
              padding: "1%",
            }}
          >
            <Link
              component={RouterLink}
              underline="hover"
              color="inherit"
              to="/"
            >
              Accueil
            </Link>
            <Link
              component={RouterLink}
              underline="hover"
              color="inherit"
              to="/products"
            >
              {products.length > 0 && products[0].category}
            </Link>
            <Typography color="text.primary">{provider}</Typography>
          </Breadcrumbs>
          {providerLogo && (
            <CardMedia
              component="img"
              image={providerLogo}
              alt={`${provider} logo`}
              sx={{
                height: isMobile ? 100 : 140,
                objectFit: "contain",
                marginBottom: 2,
              }}
            />
          )}
          <Typography variant="h4" gutterBottom>
            Carte Cadeau {provider}
          </Typography>
        </div>
        <div className="flex space-x-2 w-full items-start flex-col justify-start  gap-7">
          <Typography color="text.primary" variant="h5">
            Étape 1 : Sélectionnez le pays de provenance du produit
          </Typography>
          <Button
            variant="contained"
            sx={{
              background: "white",
              border: "solid #f5f5f5 2px",
              color: "#000",
              width: isMobile ? "80%" : "20%",
              justifyContent: "space-between",
              "&:hover": {
                background: "#f5f5f5",
              },
            }}
            onClick={handleOpenDialog}
            className="flex items-center space-x-2 text-black justify-between"
          >
            <div className="flex items-center space-x-2 text-black justify-center textt-center ">
              <span>{selectedCountry}</span>
              <SetFlag
                countryCode={getCountryCode(selectedCountry)}
                countryName={getCountryName(selectedCountry)}
              />
            </div>

            <ArrowRight />
          </Button>
        </div>
        {products.length === 0 ? (
          <div className="flex flex-col items-center justify-center gap-4 mt-8">
            <NoDataIcon style={{ fontSize: 50, color: "gray" }} />
            <Typography variant="h6" className="text-gray-700">
              Rien à trouver sur cette page 😔
            </Typography>
            <Button variant="contained" onClick={() => navigate(-1)}>
              Aller à la page précédente
            </Button>
          </div>
        ) : (
          <ProductList
            sampleProducts={products
              .filter(
                (product) =>
                  !selectedCountry || product.country === selectedCountry
              )
              .sort((a, b) => a.price - b.price)}
          />
        )}
      </Box>
      <Button
        variant="contained"
        color="primary"
        sx={{ margin: 2 }}
        onClick={handleCheckout}
      >
        Checkout
      </Button>

      <Paper
        className="bg-white gap-4 flex flex-col"
        elevation={3}
        sx={{
          padding: "16px",
          margin: "16px",
          marginLeft: "10%",
          marginRight: "10%",
          width: isMobile ? "80%" : "auto",
          background: "#fff",
        }}
      >
        <Typography variant="h5" textAlign={"center"} gutterBottom>
          Découvrez plus avec {provider}
        </Typography>

        {externalUrl && (
          <Button
            variant="contained"
            color="primary"
            href={externalUrl}
            target="_blank"
            rel="noopener noreferrer"
            sx={{ marginTop: 2 }}
          >
            Cliquez pour visiter le site {provider} et utiliser votre carte
            cadeau.
            {products[0].category === "shoppings" && (
              <Typography variant="h5" textAlign="center" gutterBottom>
                {" "}
                pour passer vos commandes de vêtements.{" "}
              </Typography>
            )}
          </Button>
        )}
        {products.length > 0 &&
          products[0].category === "shoppings" &&
          additionalInfo && (
            <Box mt={2}>
              <Typography variant="body2" textAlign={"justify"}>
                {additionalInfo}
              </Typography>
            </Box>
          )}
      </Paper>

      <div className="flex w-full items-start">
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            marginLeft: "10%",
            marginRight: "10%",
          }}
        >
          Acher une carte {provider} en ligne
        </Typography>
      </div>

      <Paper
        className=" bg-white gap-4 flex flex-col"
        elevation={3}
        sx={{
          padding: "16px",
          margin: "16px",
          marginLeft: "10%",
          marginRight: "10%",
          width: isMobile ? "80%" : "auto",
          background: "#fff",
        }}
      >
        <Typography variant="h6" textAlign={"justify"}>
          À propos de {provider}
        </Typography>
        <Divider />
        <Typography
          variant="body1"
          textAlign={"justify"}
          dangerouslySetInnerHTML={{ __html: description }}
        />
        {videoUrl !== "" && (
          <div className="flex w-full">
            <Divider />
            <div className="flex w-full items-center justify-center">
              <iframe
                src={videoUrl}
                title="Video Content"
                style={{
                  width: isMobile ? "90%" : "50%",
                  height: isMobile ? "100%" : "300px",
                  borderRadius: "8px",
                }}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </div>
        )}
      </Paper>

      {qas.length > 0 && (
        <div className="flex w-full items-start">
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              marginLeft: "10%",
              marginRight: "10%",
            }}
          >
            Questions fréquemment posées
          </Typography>
        </div>
      )}

      <Box className="flex flex-col items-center justify-center w-full gap-4">
        {qas.map((qa, index) => (
          <div
            key={index}
            className={`flex flex-col ${
              isMobile ? "w-4/5" : "w-95/100"
            } items-center justify-center`}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width={isMobile ? "100%" : "55%"}
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                cursor: "pointer",
                marginLeft: "10%",
                marginRight: "10%",
              }}
              onClick={() => handleExpandClick(String(index))}
            >
              <Typography
                variant="body1"
                textAlign={"justify"}
                sx={{
                  marginLeft: "10%",
                  marginRight: "10%",
                }}
                dangerouslySetInnerHTML={{ __html: qa.question }}
              />
              <IconButton
                aria-expanded={expandedQA.includes(String(index))}
                aria-label="show more"
              >
                {expandedQA.includes(String(index)) ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )}
              </IconButton>
            </Box>
            <Collapse
              in={expandedQA.includes(String(index))}
              timeout="auto"
              unmountOnExit
              sx={{
                width: isMobile ? "100%" : "55%",
              }}
            >
              <Typography
                sx={{
                  width: "100%",
                  padding: 2,
                  whiteSpace: "normal",
                  alignItems: "flex-start",
                  textAlign: "left",
                  marginLeft: "10%",
                  marginRight: "30%",
                }}
                dangerouslySetInnerHTML={{ __html: qa.answer }}
              />
            </Collapse>
          </div>
        ))}
      </Box>

      <Typography variant="body1" gutterBottom></Typography>
      <div className="w-full px-4 md:px-8 lg:px16 xl:px-16">
        {provider && <RssFeed search={provider} />}
      </div>

      <Footer />
      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Selection un pays</DialogTitle>
        <DialogContent>
          <TextField
            label="Search for your country"
            fullWidth
            variant="outlined"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <List>
            {filteredCountries.map((country) => (
              <ListItem
                button
                key={country.country}
                onClick={() => handleCountrySelect(country.country)}
                className="flex items-center space-x-2"
              >
                <SetFlag
                  countryCode={country.countryCode}
                  countryName={country.country}
                />
                <ListItemText primary={country.country} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </motion.div>
  );
};
