import { Box, Typography, TextField, Button, CircularProgress } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { apiSrcUrl, primaryColor } from "utils";

interface ConvertorParams {
  selectedCountry: any;
}

export const Convertor: React.FC<ConvertorParams> = ({ selectedCountry }) => {
  const [, setUsdAmount] = useState<number | string>("");
  const [, setBsbCoinAmount] = useState<number | string>("");
  const [, setLocalCurrencyAmount] = useState<
    number | string
  >("");
  const [pendingValues, setPendingValues] = useState({
    usdAmount: "",
    bsbCoinAmount: "",
    localCurrencyAmount: "",
  });
  const [loading, setLoading] = useState(false);

  const operationRef = useRef(0);

  
  useEffect(() => {
    const savedUsdAmount = localStorage.getItem("usdAmount") || "";
    const savedBsbCoinAmount = localStorage.getItem("bsbCoinAmount") || "";
    const savedLocalCurrencyAmount =
      localStorage.getItem("localCurrencyAmount") || "";

    setUsdAmount(savedUsdAmount);
    setBsbCoinAmount(savedBsbCoinAmount);
    setLocalCurrencyAmount(savedLocalCurrencyAmount);

    setPendingValues({
      usdAmount: savedUsdAmount,
      bsbCoinAmount: savedBsbCoinAmount,
      localCurrencyAmount: savedLocalCurrencyAmount,
    });
  }, []);

  const handleConfirm = async () => {
    setLoading(true);
    try {
      let type = "";
      let value = "";
      const sender = operationRef.current;

      if (sender === 1) {
        type = "bsbCoinAmount";
        value = pendingValues.bsbCoinAmount;
      } else if (sender === 2) {
        type = "usdAmount";
        value = pendingValues.usdAmount;
      } else if (sender === 3) {
        type = "localCurrencyAmount";
        value = pendingValues.localCurrencyAmount;
      }

      const response = await fetch(
        `${apiSrcUrl}/public/price/convertor/${selectedCountry.currencyCode}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            amountType: type,
            amountValue: value,
          }),
        }
      );

      const result = await response.json();

      if (result.success) {
        setUsdAmount(result.usdAmount);
        setBsbCoinAmount(result.bsbCoinAmount);
        setLocalCurrencyAmount(result.localCurrencyAmount);

        setPendingValues({
          usdAmount: result.usdAmount,
          bsbCoinAmount: result.bsbCoinAmount,
          localCurrencyAmount: result.localCurrencyAmount,
        });

        localStorage.setItem("usdAmount", result.usdAmount.toString());
        localStorage.setItem("bsbCoinAmount", result.bsbCoinAmount.toString());
        localStorage.setItem(
          "localCurrencyAmount",
          result.localCurrencyAmount.toString()
        );
      } else {
        console.error("Error in conversion:", result.error);
      }
    } catch (error) {
      console.error("Error handling conversion:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (field: string, value: string) => {
    setPendingValues((prev) => ({
      usdAmount: field === "usdAmount" ? value : "",
      bsbCoinAmount: field === "bsbCoinAmount" ? value : "",
      localCurrencyAmount: field === "localCurrencyAmount" ? value : "",
    }));

    if (field === "usdAmount") operationRef.current = 2;
    if (field === "bsbCoinAmount") operationRef.current = 1;
    if (field === "localCurrencyAmount") operationRef.current = 3;
  };

  return (
    <Box className="flex justify-center flex-col items-center gap-5">
      <Box
        className="flex justify-center flex-col items-center gap-5"
        sx={{
          px: { xs: 2, sm: 4, md: 8, lg: 16 },
          py: { xs: 2, sm: 4, md: 6, lg: "auto" },
        }}
      >
        <Typography variant="h3" sx={{ marginBottom: 1 }}>
          Convertisseur des BSB COiNS
        </Typography>
        <Box sx={{ textAlign: "center", marginBottom: 4, height: "70px" }}>
          {loading && (
            <Box>
              <CircularProgress />
              <Typography variant="body1">
                Conversion en cours, veuillez patienter...
              </Typography>
            </Box>
          )}
        </Box>
        <Box sx={{ marginBottom: 4 }}>
          <TextField
            label="BSB COINS"
            value={pendingValues.bsbCoinAmount}
            onChange={(e) =>
              handleInputChange("bsbCoinAmount", e.target.value)
            }
            variant="outlined"
            fullWidth
            type="number"
            inputProps={{ min: 1 }}
            sx={{ marginBottom: 2 }}
          />
          <TextField
            label="USD"
            value={pendingValues.usdAmount}
            onChange={(e) => handleInputChange("usdAmount", e.target.value)}
            type="number"
            inputProps={{ min: 1 }}
            variant="outlined"
            fullWidth
            sx={{ marginBottom: 2 }}
          />
          <TextField
            label={`Devise locale (${selectedCountry.currencyCode})`}
            value={pendingValues.localCurrencyAmount}
            onChange={(e) =>
              handleInputChange("localCurrencyAmount", e.target.value)
            }
            type="number"
            inputProps={{ min: 1 }}
            variant="outlined"
            fullWidth
            sx={{ marginBottom: 2 }}
          />
          <Button
            variant="contained"
            sx={{backgroundColor:primaryColor}}
            fullWidth
            onClick={handleConfirm}
            disabled={loading}
          >
            Confirmer
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
